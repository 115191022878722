/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoadLineStringGeom } from './RoadLineStringGeom';
import {
    RoadLineStringGeomFromJSON,
    RoadLineStringGeomFromJSONTyped,
    RoadLineStringGeomToJSON,
} from './RoadLineStringGeom';

/**
 * 
 * @export
 * @interface RoadCreateUpdateRequest
 */
export interface RoadCreateUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof RoadCreateUpdateRequest
     */
    scenario: number;
    /**
     * 
     * @type {RoadLineStringGeom}
     * @memberof RoadCreateUpdateRequest
     */
    geom: RoadLineStringGeom;
    /**
     * 
     * @type {number}
     * @memberof RoadCreateUpdateRequest
     */
    source: number;
    /**
     * 
     * @type {number}
     * @memberof RoadCreateUpdateRequest
     */
    target: number;
    /**
     * 
     * @type {number}
     * @memberof RoadCreateUpdateRequest
     */
    speed: number;
    /**
     * 
     * @type {number}
     * @memberof RoadCreateUpdateRequest
     */
    reverseSpeed: number;
}

/**
 * Check if a given object implements the RoadCreateUpdateRequest interface.
 */
export function instanceOfRoadCreateUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scenario" in value;
    isInstance = isInstance && "geom" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "speed" in value;
    isInstance = isInstance && "reverseSpeed" in value;

    return isInstance;
}

export function RoadCreateUpdateRequestFromJSON(json: any): RoadCreateUpdateRequest {
    return RoadCreateUpdateRequestFromJSONTyped(json, false);
}

export function RoadCreateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoadCreateUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scenario': json['scenario'],
        'geom': RoadLineStringGeomFromJSON(json['geom']),
        'source': json['source'],
        'target': json['target'],
        'speed': json['speed'],
        'reverseSpeed': json['reverse_speed'],
    };
}

export function RoadCreateUpdateRequestToJSON(value?: RoadCreateUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scenario': value.scenario,
        'geom': RoadLineStringGeomToJSON(value.geom),
        'source': value.source,
        'target': value.target,
        'speed': value.speed,
        'reverse_speed': value.reverseSpeed,
    };
}

