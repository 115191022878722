/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScenarioInfoResponse
 */
export interface ScenarioInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    handled: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    lon: number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioInfoResponse
     */
    baseTopology: number;
}

/**
 * Check if a given object implements the ScenarioInfoResponse interface.
 */
export function instanceOfScenarioInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "handled" in value;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lon" in value;
    isInstance = isInstance && "baseTopology" in value;

    return isInstance;
}

export function ScenarioInfoResponseFromJSON(json: any): ScenarioInfoResponse {
    return ScenarioInfoResponseFromJSONTyped(json, false);
}

export function ScenarioInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'handled': json['handled'],
        'lat': json['lat'],
        'lon': json['lon'],
        'baseTopology': json['base_topology'],
    };
}

export function ScenarioInfoResponseToJSON(value?: ScenarioInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'handled': value.handled,
        'lat': value.lat,
        'lon': value.lon,
        'base_topology': value.baseTopology,
    };
}

