import { CategoryEnum } from "src/client/lib/models";
import { ScenarioTypeEnum } from "src/client/lib/models/ScenarioTypeEnum";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class NewProjectStateHandler {
  // new project screen
  initNewProjectScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projects.length === 0) {
      this.state.popUpState = PopUpState.welcome;
    }
    // Init options list for new project
    this.state.newProject.InitSenarioTypes = [
      {
        id: 1,
        label: "Bereikbaarheid in beeld",
        value: ScenarioTypeEnum.AccessibilityInView,
        active: true,
      },
      {
        id: 2,
        label: "Bicycle Oriented Development",
        value: ScenarioTypeEnum.BicycleOrientedDevelopment,
        active: true,
      },
      {
        id: 3,
        label: "Stationsgebieden OV",
        value: ScenarioTypeEnum.PublicTransportStationAreas,
        active: false,
      },
      {
        id: 4,
        label: "Scholieren op de kaart",
        value: ScenarioTypeEnum.StudentsOnTheMap,
        active: false,
      },
      {
        id: 5,
        label: "Impact werkzaamheden",
        value: ScenarioTypeEnum.StudentsOnTheMap,
        active: false,
      },
    ];
    this.fetchDestinationGroupList(callback);
    this.state.newProject.selectedDestinationGroupList = [];
    this.fetchTopologyList(callback);
    this.state.newProject.selectedTopology = null;
    this.state.screenState = ScreenState.NewProject;
    this.state.newProject.form_stage = 1;
    this.state.newProject.name = null;
    this.state.newProject.projectArea.areaClosed = false;
    this.state.newProject.projectArea.coordinates = [];
    this.state.newProject.projectArea.mousePosition = null;

    callback(this.state);
  }

  updateNewProjectName(
    this: AppStateHandler,
    newProjectName: string | null,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newProject.name = newProjectName;
    callback(this.state);
  }

  removeSelectedDestinationGroup(
    this: AppStateHandler,
    destinationGroupId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newProject.selectedDestinationGroupList =
      this.state.newProject.selectedDestinationGroupList.filter(
        (x) => x !== destinationGroupId
      );
    callback(this.state);
  }

  addSelectedDestinationGroup(
    this: AppStateHandler,
    destinationGroupId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newProject.selectedDestinationGroupList.push(destinationGroupId);
    callback(this.state);
  }

  fetchDestinationGroupList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.destinationGroupApi.apiDestinationGroupList().then((result) => {
      this.state.newProject.destinationGroupList = result.map(
        (destinationGroup) => ({
          id: destinationGroup.id,
          label: destinationGroup.name,
          value: destinationGroup.name,
          active: true,
        })
      );
      callback(this.state);
    });
  }

  fetchTopologyList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.topologyApi.apiTopologyList().then((result) => {
      this.state.newProject.topologyList = result.map((topology) => ({
        id: topology.id,
        label: topology.name,
        value: topology.name,
        active: true,
      }));
      callback(this.state);
    });
  }

  saveNewProject(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.newProject.name === null ||
      this.state.newProject.name === ""
    ) {
      this.displayAlert(
        "⚠️ Vul alstublieft een projectnaam in.",
        5000,
        callback
      );
    }

    if (
      this.state.newProject.projectArea.areaClosed === false ||
      this.state.newProject.projectArea.coordinates.length <= 2
    ) {
      this.displayAlert("⚠️ Teken een gebied in", 5000, callback);
    }

    if (this.state.newProject.destinationGroupList.length < 1) {
      this.displayAlert(
        "⚠️ Kies alstublieft minstens 1 bestemming.",
        5000,
        callback
      );
    }

    if (this.state.newProject.selectedTopology === null) {
      this.displayAlert("⚠️ Kies 1 wegennetwerk.", 5000, callback);
    }

    if (
      this.state.newProject.name === null ||
      this.state.newProject.name === "" ||
      this.state.newProject.projectArea.areaClosed === false ||
      this.state.newProject.projectArea.coordinates.length <= 2 ||
      this.state.newProject.destinationGroupList.length < 1 ||
      this.state.newProject.selectedTopology === null
    ) {
      return;
    }

    this.projectApi
      .apiProjectCreate({
        projectCreateRequest: {
          name: this.state.newProject.name,
          area: {
            type: "Polygon",
            coordinates: [
              this.state.newProject.projectArea.coordinates.map((c) => [
                c.lng,
                c.lat,
              ]),
            ],
          },
          baseTopology: this.state.newProject.selectedTopology,
          destinationGroups: this.state.newProject.selectedDestinationGroupList,
        },
      })
      .then((response) => {
        this.loadProjects(() => {
          this.state.selectedScenario = null;
          this.state.selectedProject = response.id;
          this.state.newProject.name = null;
          this.state.newProject.projectArea.areaClosed = false;
          this.state.newProject.projectArea.coordinates = [];
          this.state.newProject.projectArea.mousePosition = null;
          this.state.newProject.destinationGroupList = [];
          this.state.newScenario.name = "Huidige situatie";
          this.state.newScenario.category = CategoryEnum.CurrentScenario;
          this.saveNewScenario(false, callback);
        });
      })
      .catch((error) => {
        console.log("Error while creating new project", error);
        //sign out
        callback(this.state);
      });
  }
}
