import React from "react";
import "./MainButton.css";

interface MainButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  icon?: any;
  className?: string;
}

export default class MainButton extends React.Component<MainButtonProps> {
  render() {
    return (
      <div
        className={this.props.className}
        onClick={() => {
          if (this.props.onClick !== undefined) {
            this.props.onClick();
          }
        }}
      >
        {this.props.icon ? this.props.icon : null} &nbsp; {this.props.children}
      </div>
    );
  }
}
