import { LatLng, LatLngBounds, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import {
  CircleMarker,
  FeatureGroup,
  MapContainer,
  Polygon,
  TileLayer,
  useMap,
} from "react-leaflet";
import { ContentTypeEnum } from "src/client/lib/models";
import "./MapResultBlockHeatmap.css";

interface ParsedHeatmap {
  centerPoint: LatLng;
  grid: {
    geometry: LatLng[];
    group: number;
    value: number;
  }[];
  points: {
    id: number;
    geometry: LatLng;
    name: string;
  }[];
}

interface MapResultBlockHeatmapProps {
  geoJsonString: string;
  mapType: ContentTypeEnum;
  overviewGeoJsonString: string;
}

export default class MapResultBlockHeatmap extends React.Component<MapResultBlockHeatmapProps> {
  center: LatLng = new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 7;

  HeatMapColors: {
    group: number;
    color: string;
  }[] = [
    {
      group: 1,
      color: "#47B585",
    },
    {
      group: 2,
      color: "#91cba8",
    },
    {
      group: 3,
      color: "#ddf1b4",
    },
    {
      group: 4,
      color: "#fedf99",
    },
    {
      group: 5,
      color: "#f59053",
    },
    {
      group: 6,
      color: "#d7191c",
    },
  ];

  DiffHeatMapColors: {
    group: number;
    color: string;
  }[] = [
    {
      group: 1,
      color: "#80a2ff",
    },
    {
      group: 2,
      color: "#5280ff",
    },
    {
      group: 3,
      color: "#245eff",
    },
    {
      group: 4,
      color: "#0041f5",
    },
    {
      group: 5,
      color: "#0035c7",
    },
    {
      group: 6,
      color: "#002999",
    },
  ];

  heatMapZoomToBounds(map: Map, heatMapObject: ParsedHeatmap) {
    if (heatMapObject.grid.length > 0) {
      let north: number | null = null;
      let east: number | null = null;
      let south: number | null = null;
      let west: number | null = null;

      heatMapObject.grid.forEach((gridItem) => {
        gridItem.geometry.forEach((point) => {
          if (
            north === null &&
            east === null &&
            south === null &&
            west === null
          ) {
            north = point.lat;
            east = point.lng;
            south = point.lat;
            west = point.lng;
          } else if (north && east && south && west) {
            if (point.lat > north) {
              north = point.lat;
            }
            if (point.lng > east) {
              east = point.lng;
            }
            if (point.lat < south) {
              south = point.lat;
            }
            if (point.lng < west) {
              west = point.lng;
            }
          }
        });
      });

      if (north && east && south && west) {
        map.fitBounds(
          new LatLngBounds(new LatLng(south, west), new LatLng(north, east))
        );
      } else {
        map.setView(heatMapObject.centerPoint, 12);
      }
    } else {
      map.setView(heatMapObject.centerPoint, 12);
    }
  }

  parseHeatmapJsonString(geojson: string): ParsedHeatmap {
    const returnData: ParsedHeatmap = {
      centerPoint: this.center,
      grid: [],
      points: [],
    };

    try {
      // parse json
      const heatmapObject = JSON.parse(geojson);

      // validate data then return
      if (
        "points" in heatmapObject &&
        "grid" in heatmapObject &&
        "center" in heatmapObject
      ) {
        // Get grid items
        heatmapObject.grid.forEach((gridItem: any) => {
          if (
            "geometry" in gridItem &&
            "group" in gridItem &&
            "value" in gridItem
          ) {
            returnData.grid.push({
              geometry: gridItem.geometry[0].map(
                (c: number[]) => new LatLng(c[1], c[0])
              ),
              group: gridItem.group,
              value: gridItem.value,
            });
          }
        });

        // Get points
        heatmapObject.points.forEach((point: any) => {
          if (
            "id" in point &&
            "name" in point &&
            "x" in point &&
            "y" in point
          ) {
            returnData.points.push({
              id: point.id,
              geometry: new LatLng(point.y, point.x),
              name: point.name,
            });
          }
        });

        // Get the center point
        if (
          "latitude" in heatmapObject.center &&
          "longitude" in heatmapObject.center
        ) {
          returnData.centerPoint = new LatLng(
            heatmapObject.center.latitude,
            heatmapObject.center.longitude
          );
        }
      } else {
        // Json has old structure
        // This whas only build for backwards compatibility
        // TODO: Delete the else statement in the future
        if (
          "features" in heatmapObject &&
          "properties" in heatmapObject &&
          "type" in heatmapObject
        ) {
          if (
            heatmapObject.type === "FeatureCollection" &&
            "latitude" in heatmapObject.properties &&
            "longitude" in heatmapObject.properties
          ) {
            heatmapObject.features.forEach((feature: any) => {
              if (
                "geometry" in feature &&
                "properties" in feature &&
                "type" in feature
              ) {
                if (
                  "coordinates" in feature.geometry &&
                  "type" in feature.geometry &&
                  "group" in feature.properties &&
                  feature.type === "Feature"
                ) {
                  if (feature.geometry.type === "Polygon") {
                    returnData.grid.push({
                      geometry: feature.geometry.coordinates[0].map(
                        (c: number[]) => new LatLng(c[1], c[0])
                      ),
                      group: feature.properties.group,
                      value:
                        "value" in feature.properties
                          ? feature.properties.value
                          : 0,
                    });
                  }
                }
              }
            });

            returnData.centerPoint = new LatLng(
              heatmapObject.properties.latitude,
              heatmapObject.properties.longitude
            );
          }
        }
      }

      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        centerPoint: this.center,
        grid: [],
        points: [],
      };
    }
  }

  parseOverviewJsonString(geojson: string): LatLng[] | null {
    let returnData: LatLng[] | null = null; // The array of LatLng points to return

    try {
      // Parse the GeoJSON string
      const overviewObject = JSON.parse(geojson.replaceAll("'", '"'));

      // Validate the data
      if ("coordinates" in overviewObject && "type" in overviewObject) {
        // Check if the GeoJSON object represents a Polygon with valid coordinates
        if (
          overviewObject.type === "Polygon" &&
          overviewObject.coordinates.length > 0
        ) {
          // Map the coordinates to an array of LatLng points
          returnData = overviewObject.coordinates[0].map(
            (coordinate: number[]) => {
              return new LatLng(coordinate[1], coordinate[0]);
            }
          );
        }
      }

      // Return the parsed data
      return returnData;
    } catch (error) {
      // Log and handle any parsing errors
      console.error("Error parsing JSON:", error);
      return null;
    }
  }
  Heatmap = (): JSX.Element => {
    const map = useMap();

    // From string to heatmap object
    const heatMapObject = this.parseHeatmapJsonString(this.props.geoJsonString);

    // Zoom to layer
    this.heatMapZoomToBounds(map, heatMapObject);

    // Draw on the map
    return (
      <>
        <FeatureGroup>
          {heatMapObject.grid.map((polygon, index) => {
            return (
              <Polygon
                key={index}
                pathOptions={{
                  weight: 0,
                  fillColor: (
                    (this.props.mapType === ContentTypeEnum.HeatmapDiff
                      ? this.DiffHeatMapColors.find(
                          ({ group }) => group === polygon.group
                        )
                      : this.HeatMapColors.find(
                          ({ group }) => group === polygon.group
                        )) || { color: "#2b83ba" }
                  ).color,
                  fillOpacity: 0.5,
                }}
                positions={polygon.geometry}
              />
            );
          })}
        </FeatureGroup>
        <FeatureGroup>
          {heatMapObject.points.map((point, index) => {
            return (
              <CircleMarker
                key={index}
                center={point.geometry}
                radius={3}
                weight={0}
                fillColor={"#2b83ba"}
                opacity={1}
                fillOpacity={1}
              />
            );
          })}
        </FeatureGroup>
      </>
    );
  };

  Overview = (): JSX.Element => {
    const overview = this.parseOverviewJsonString(
      this.props.overviewGeoJsonString
    );

    // Draw on the overview
    if (overview !== null) {
      return (
        <Polygon
          pathOptions={{
            opacity: 1,
            weight: 1.5,
            lineCap: "round",
            lineJoin: "round",
            color: "#5d87ff",
            fill: false,
          }}
          positions={overview}
        />
      );
    } else {
      return <></>;
    }
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        zoomControl={false}
        attributionControl={false}
        id={"MapResultBlockHeatmap"}
      >
        <TileLayer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
        <this.Heatmap />
        <this.Overview />
      </MapContainer>
    );
  }
}
