import React from "react";
import "./SectionTitle.css";
interface SectionTitleProps {
  children?: React.ReactNode;
  subtitle?: boolean;
}

export default class SectionTitle extends React.Component<SectionTitleProps> {
  render() {
    if (this.props.subtitle) {
      return <h4 className="SectionTitle">{this.props.children}</h4>;
    } else {
      return <h3 className="SectionTitle">{this.props.children}</h3>;
    }
  }
}
