import { AppStateHandler } from "../AppStateHandler";
import { AppStateType, ScreenState } from "../Types";

export class ProjectBarStateHandler {
  loadProjects(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiProjectList()
      .then((response) => {
        this.state.projects = response;

        // Select first ScenarioResult
        for (let index = 0; index < this.state.projects.length; index++) {
          if (this.state.projects[index].scenarioList.length > 0) {
            this.state.selectedScenario =
              this.state.projects[index].scenarioList[0].id;
            this.state.selectedProject = null;
            break;
          }
        }

        callback(this.state);
      })
      .catch((error) => {
        console.log("Error while loading projects", error);
      });
  }

  changeSelectedProject(
    this: AppStateHandler,
    selectedProject: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.selectedScenario = null;
    this.state.selectedProject = selectedProject;
    this.changeScreen(ScreenState.Project, callback);
    callback(this.state);
  }

  changeSelectedScenario(
    this: AppStateHandler,
    selectedScenario: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.selectedScenario = selectedScenario;
    this.state.selectedProject = null;
    this.changeScreen(ScreenState.ScenarioResult, callback);
    callback(this.state);
  }
}
