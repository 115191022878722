import React from "react";
import "./DropDown.css";

export interface DropDownListItem {
  key: number;
  name: string;
  disabled: boolean;
}

interface DropDownProps {
  list: DropDownListItem[];
  onChange: (newKey: number) => void;
  selectedKey: number;
}

export default class DropDown extends React.Component<DropDownProps> {
  render() {
    return (
      <select
        onChange={(event) => {
          const newKey: number = parseInt(event.target.value);
          this.props.onChange(newKey);
        }}
        className={"DropDown"}
        value={this.props.selectedKey}
      >
        <option value={-1} disabled>
          Selecteer een item
        </option>
        {this.props.list.map((i) => (
          <option key={i.key} value={i.key} disabled={i.disabled}>
            {i.name}
          </option>
        ))}
      </select>
    );
  }
}
