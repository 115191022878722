import { FC, useEffect, useState } from "react";
import "./ResultBlockTable.css";
interface TableProps {
  jsonString: string;
}

const Table: FC<TableProps> = ({ jsonString }) => {
  const [tableData, setTableData] = useState<any>(null);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(jsonString);
      setTableData(parsedData.table);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, [jsonString]);

  if (!tableData) {
    return <div>Error parsing JSON</div>;
  }

  return (
    <table className="ResultBlocksTable">
      <thead className="ResultBlocksTablethead">
        {/* <tr>
          {tableData.headers.map((header: string, index: number) => (
            <th key={index}>{header}</th>
          ))}
        </tr> */}
      </thead>
      <tbody className="ResultBlocksTable-tbody">
        {tableData.rows.map((row: string[], rowIndex: number) => (
          <tr className="ResultBlocksTableTr" key={rowIndex}>
            {row.map((cell: string, cellIndex: number) => (
              <td className="ResultBlocksTableTd" key={cellIndex}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
