import { LatLng, LatLngBounds, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import {
  FeatureGroup,
  MapContainer,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import "./MapPupilDensityNetwork.css";

interface ParsedPupilDensityNetwork {
  centerPoint: LatLng;
  roads: {
    geometry: LatLng[];
    group: number;
    value: number;
  }[];
}

interface MapPupilDensityNetworkProps {
  geoJsonString: string;
}

export default class MapPupilDensityNetwork extends React.Component<MapPupilDensityNetworkProps> {
  center: LatLng = new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 7;

  NetworkColors: {
    group: number;
    color: string;
  }[] = [
    {
      group: 1,
      color: "#faebdd",
    },
    {
      group: 2,
      color: "#f58860",
    },
    {
      group: 3,
      color: "#cb1b4f",
    },
    {
      group: 4,
      color: "#611f53",
    },
    {
      group: 5,
      color: "#03051a",
    },
  ];

  zoomToBounds(map: Map, roadsObject: ParsedPupilDensityNetwork) {
    if (roadsObject.roads.length > 0) {
      let north: number | null = null;
      let east: number | null = null;
      let south: number | null = null;
      let west: number | null = null;

      roadsObject.roads.forEach((road) => {
        road.geometry.forEach((point) => {
          if (
            north === null &&
            east === null &&
            south === null &&
            west === null
          ) {
            north = point.lat;
            east = point.lng;
            south = point.lat;
            west = point.lng;
          } else if (north && east && south && west) {
            if (point.lat > north) {
              north = point.lat;
            }
            if (point.lng > east) {
              east = point.lng;
            }
            if (point.lat < south) {
              south = point.lat;
            }
            if (point.lng < west) {
              west = point.lng;
            }
          }
        });
      });

      if (north && east && south && west) {
        map.fitBounds(
          new LatLngBounds(new LatLng(south, west), new LatLng(north, east))
        );
      } else {
        map.setView(roadsObject.centerPoint, 12);
      }
    } else {
      map.setView(roadsObject.centerPoint, 12);
    }
  }

  parseJsonString(geojson: string): ParsedPupilDensityNetwork {
    const returnData: ParsedPupilDensityNetwork = {
      centerPoint: this.center,
      roads: [],
    };

    try {
      // parse json
      const heatmapObject = JSON.parse(geojson);

      // validate data then return
      if ("roads" in heatmapObject && "center" in heatmapObject) {
        // Get grid items
        heatmapObject.roads.forEach((road: any) => {
          if ("geometry" in road && "group" in road && "value" in road) {
            returnData.roads.push({
              geometry: road.geometry.map(
                (c: number[]) => new LatLng(c[1], c[0])
              ),
              group: road.group,
              value: road.value,
            });
          }
        });

        // Get the center point
        if (
          "latitude" in heatmapObject.center &&
          "longitude" in heatmapObject.center
        ) {
          returnData.centerPoint = new LatLng(
            heatmapObject.center.latitude,
            heatmapObject.center.longitude
          );
        }
      }

      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        centerPoint: this.center,
        roads: [],
      };
    }
  }

  parseOverviewJsonString(geojson: string): LatLng[] | null {
    let returnData: LatLng[] | null = null; // The array of LatLng points to return

    try {
      // Parse the GeoJSON string
      const overviewObject = JSON.parse(geojson.replaceAll("'", '"'));

      // Validate the data
      if ("coordinates" in overviewObject && "type" in overviewObject) {
        // Check if the GeoJSON object represents a Polygon with valid coordinates
        if (
          overviewObject.type === "Polygon" &&
          overviewObject.coordinates.length > 0
        ) {
          // Map the coordinates to an array of LatLng points
          returnData = overviewObject.coordinates[0].map(
            (coordinate: number[]) => {
              return new LatLng(coordinate[1], coordinate[0]);
            }
          );
        }
      }

      // Return the parsed data
      return returnData;
    } catch (error) {
      // Log and handle any parsing errors
      console.error("Error parsing JSON:", error);
      return null;
    }
  }

  AddCustomPanes = () => {
    const map = useMap();

    useEffect(() => {
      this.NetworkColors.forEach((group) => {
        map.createPane(`pane_${group.group}`);
        map.getPane(`pane_${group.group}`)!.style.zIndex = `${
          650 + group.group
        }`;
      });
    }, [map]);

    return null;
  };

  PupilDensityNetwork = (): JSX.Element => {
    const map = useMap();

    // From string to roads object
    const roadsObject = this.parseJsonString(this.props.geoJsonString);

    // Zoom to layer
    this.zoomToBounds(map, roadsObject);

    // Draw on the map
    return (
      <FeatureGroup>
        {roadsObject.roads.map((line, index) => {
          return (
            <Polyline
              key={index}
              pathOptions={{
                weight: 2,
                color: (
                  this.NetworkColors.find(
                    ({ group }) => group === line.group
                  ) || { color: "#2b83ba" }
                ).color,
              }}
              positions={line.geometry}
              pane={`pane_${line.group}`}
            />
          );
        })}
      </FeatureGroup>
    );
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        zoomControl={false}
        attributionControl={false}
        id={"MapPupilDensityNetwork"}
      >
        <this.AddCustomPanes />
        <TileLayer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
        <this.PupilDensityNetwork />
      </MapContainer>
    );
  }
}
