import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LoginStateHandler {
  loginFormEmailChange(
    this: AppStateHandler,
    newEmail: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.auth.email = newEmail;
    callback(this.state);
  }

  loginFormPasswordChange(
    this: AppStateHandler,
    newPassword: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.auth.password = newPassword;
    callback(this.state);
  }

  login(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    if (this.state.auth.email !== null && this.state.auth.password !== null) {
      this.authApi
        .authLoginCreate({
          login: {
            email: this.state.auth.email,
            password: this.state.auth.password,
          },
        })
        .then((response) => {
          if (response.access !== null && response.refresh !== null) {
            this.setTokens(response.access, response.refresh, callback);
            this.loadProjects(() => {
              const has_projects = this.state.projects.length > 0;
              if (has_projects) {
                this.changeScreen(ScreenState.ScenarioResult, () => {
                  this.current_user(callback);
                });
              } else {
                this.changeScreen(ScreenState.NewProject, () => {
                  this.current_user(callback);
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log("Error while logging in", error);
        });
    }
  }
}
