import L, { FitBoundsOptions, Icon, LatLng, PointExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import { GoXCircleFill } from "react-icons/go";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import MainButton from "./MainButton";

import "./ViewCustomLayers.css";

interface ViewCustomLayersProps {
  name: string;
  type: string;
  deleteOnClick?: () => void;
  points: LatLng[];
}

const customIcon = new Icon({
  iconUrl:
    "https://img.icons8.com/?size=100&id=Hh5ONdvsAI4P&format=png&color=000000",
  iconSize: [25, 25], // size of the icon
});

const ViewCustomLayers: React.FC<ViewCustomLayersProps> = ({
  name,
  type,
  deleteOnClick,
  points,
}) => {
  const FitBounds = ({ points }: { points: LatLng[] }) => {
    const map = useMap();

    useEffect(() => {
      const bounds = L.latLngBounds(
        points.map((point) => [point.lat, point.lng])
      );
      const fitBoundsOptions: FitBoundsOptions = {
        padding: [50, 50] as PointExpression,
      };
      map.fitBounds(bounds, fitBoundsOptions);
    }, [map, points]);

    return null;
  };

  return (
    <div className="card">
      <div className="card-img">
        {" "}
        <MapContainer
          style={{ height: "400px", width: "100%" }}
          zoom={13}
          center={[points[0].lat, points[0].lng]}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {points.map((point, index) => (
            <Marker
              key={index}
              position={[point.lat, point.lng]}
              icon={customIcon}
            />
          ))}
          <FitBounds points={points} />
        </MapContainer>
      </div>
      <div className="card-body">
        <div className="card-header">
          <h5 className="card-title">{name}</h5>
          <div className="card-button">
            <MainButton
              onClick={() => {
                if (deleteOnClick !== undefined) {
                  deleteOnClick();
                }
              }}
              className="MainBtn Danger"
              icon={<GoXCircleFill size={12} />}
            >
              Bestemmingenlaag verwijderen
            </MainButton>
          </div>
        </div>

        {/* <p className="card-text">{type}</p> */}
      </div>
    </div>
  );
};

export default ViewCustomLayers;
