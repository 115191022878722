import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class CreateCustomAnalysisLayersStateHandler {
  // New Analysis Layer screen
  initCreateCustomAnalysisLayersScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.CreateCustomAnalysisLayersScreen;
    this.state.newAnalysisLayer.name = "";
    this.state.newAnalysisLayer.destinations = [];
    callback(this.state);
  }

  saveAnalysisLayer(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // Check if the layer has a name and at least one point
    if (
      this.state.newAnalysisLayer.name === "" ||
      this.state.newAnalysisLayer.destinations.length === 0
    ) {
      return;
    }

    // Map the points to GeoJSON features
    const points = this.state.newAnalysisLayer.destinations.map(
      (destination) => ({
        name: destination.name,
        geom: {
          type: "Point",
          coordinates: [destination.geom.lng, destination.geom.lat],
        },
      })
    );

    /**
     * Saves the analysis layer to the server using the CustomAnalysisLayersApi.
     * If successful, it changes the screen to the CustomAnalysisLayersScreen.
     * If there is an error, it logs the error to the console.
     */
    this.destinationGroupApi
      .apiDestinationGroupCreate({
        // Create the custom analysis layer object with the name and points
        destinationGroupCreateRequest: {
          name: this.state.newAnalysisLayer.name,
          destinations: points,
          type: this.state.newAnalysisLayer.type,
        },
      })
      .then(() => {
        // Change the screen to the CustomAnalysisLayersScreen
        this.changeScreen(ScreenState.CustomAnalysisLayersScreen, callback);
      })
      .catch((error) => {
        // Log the error to the console
        console.log("Error while saving analysis layer", error);
      });
  }
}
