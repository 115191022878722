import React from "react";
import "./InfoMessagePopup.css";

interface InfoMessagePopupProps {
  message?: string;
  massageTitle: string;
  button?: any;
  Table?: { color: string; label: string }[];
}

export default class InfoMessagePopup extends React.Component<InfoMessagePopupProps> {
  render() {
    // Parse the table data if it is a string
    let parsedTable = this.props.Table;
    if (parsedTable !== undefined && !Array.isArray(parsedTable)) {
      parsedTable = JSON.parse(parsedTable as string);
    }

    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          {/* Display the title */}
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>

          {/* Display the table if it exists and is an array */}
          {parsedTable && Array.isArray(parsedTable) ? (
            <div>
              <table id="MessagePopup-table">
                <tbody>
                  {/* Map over the table data and display each row */}
                  {parsedTable.map((row) => (
                    <tr key={row.label}>
                      <td id="MessagePopup-td">{row.label}</td>
                      <td id="MessagePopup-td">
                        {/* Display a colored div for each row */}
                        <div
                          style={{
                            width: "50px",
                            height: "20px",
                            backgroundColor: row.color,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></> // Display nothing if the table is not an array
          )}

          {/* Display the message if it exists */}
          {this.props.message ? (
            <div id="MessagePopup-Text">{this.props.message}</div>
          ) : (
            <></> // Display nothing if the message is not defined
          )}

          {/* Display the buttons */}
          <div id="MessagePopup-Buttons">{this.props.button}</div>
        </div>
      </div>
    );
  }
}
