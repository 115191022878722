/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `intercitytrain` - intercitytrain
 * * `regionaltrain` - regionaltrain
 * * `bus` - bus
 * * `tram` - tram
 * * `metro` - metro
 * * `citycenter` - citycenter
 * * `shoppingcenter` - shoppingcenter
 * * `smallshop` - smallshop
 * * `elementaryschool` - elementaryschool
 * * `highschool` - highschool
 * * `commuting` - commuting
 * @export
 */
export const TypeEnum = {
    Intercitytrain: 'intercitytrain',
    Regionaltrain: 'regionaltrain',
    Bus: 'bus',
    Tram: 'tram',
    Metro: 'metro',
    Citycenter: 'citycenter',
    Shoppingcenter: 'shoppingcenter',
    Smallshop: 'smallshop',
    Elementaryschool: 'elementaryschool',
    Highschool: 'highschool',
    Commuting: 'commuting'
} as const;
export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];


export function TypeEnumFromJSON(json: any): TypeEnum {
    return TypeEnumFromJSONTyped(json, false);
}

export function TypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeEnum {
    return json as TypeEnum;
}

export function TypeEnumToJSON(value?: TypeEnum | null): any {
    return value as any;
}

