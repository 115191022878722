import { LatLng } from "leaflet";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class CustomAnalysisLayersStateHandler {
  // docs screen
  initCustomAnalysisLayersScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.CustomAnalysisLayersScreen;
    this.loadCustomAnalysisLayers(callback);
    callback(this.state);
  }

  loadCustomAnalysisLayers(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.destinationGroupApi.apiDestinationGroupList().then((res) => {
      this.state.customAnalysisLayers = res
        .filter((destinationGroup) => {
          return !destinationGroup._public;
        })
        .map((item) => ({
          id: item.id,
          name: item.name,
          destinations: item.destinations.map((destination) => {
            return {
              name: destination.name,
              geom: new LatLng(
                destination.geom.coordinates[1],
                destination.geom.coordinates[0]
              ),
            };
          }),
          type: item.type,
        }));
      callback(this.state);
    });
  }

  DeleteCustomAnalysisLayer(
    this: AppStateHandler,
    destinationGroupId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.destinationGroupApi
      .apiDestinationGroupDestroy({ id: destinationGroupId })
      .then()
      .then(() => {
        this.loadCustomAnalysisLayers(callback);
      });
    callback(this.state);
  }
}
