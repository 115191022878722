/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HousingPlanObjectRequest,
  HousingPlanObjectResponse,
  HousingPlanRequest,
  HousingPlanResponse,
} from '../models';
import {
    HousingPlanObjectRequestFromJSON,
    HousingPlanObjectRequestToJSON,
    HousingPlanObjectResponseFromJSON,
    HousingPlanObjectResponseToJSON,
    HousingPlanRequestFromJSON,
    HousingPlanRequestToJSON,
    HousingPlanResponseFromJSON,
    HousingPlanResponseToJSON,
} from '../models';

export interface ApiHousingPlanCreateRequest {
    housingPlanRequest: HousingPlanRequest;
}

export interface ApiHousingPlanDestroyRequest {
    id: number;
}

export interface ApiHousingPlanListRequest {
    scenario: number;
}

export interface ApiHousingPlanUpdateRequest {
    id: number;
    housingPlanObjectRequest: HousingPlanObjectRequest;
}

/**
 * 
 */
export class HousingPlanApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a new housing plan
     */
    async apiHousingPlanCreateRaw(requestParameters: ApiHousingPlanCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HousingPlanResponse>> {
        if (requestParameters.housingPlanRequest === null || requestParameters.housingPlanRequest === undefined) {
            throw new runtime.RequiredError('housingPlanRequest','Required parameter requestParameters.housingPlanRequest was null or undefined when calling apiHousingPlanCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/housing-plan/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HousingPlanRequestToJSON(requestParameters.housingPlanRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HousingPlanResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a new housing plan
     */
    async apiHousingPlanCreate(requestParameters: ApiHousingPlanCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HousingPlanResponse> {
        const response = await this.apiHousingPlanCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a housing paln
     */
    async apiHousingPlanDestroyRaw(requestParameters: ApiHousingPlanDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHousingPlanDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/housing-plan/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a housing paln
     */
    async apiHousingPlanDestroy(requestParameters: ApiHousingPlanDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiHousingPlanDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of housing plans per scenario
     */
    async apiHousingPlanListRaw(requestParameters: ApiHousingPlanListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HousingPlanResponse>>> {
        if (requestParameters.scenario === null || requestParameters.scenario === undefined) {
            throw new runtime.RequiredError('scenario','Required parameter requestParameters.scenario was null or undefined when calling apiHousingPlanList.');
        }

        const queryParameters: any = {};

        if (requestParameters.scenario !== undefined) {
            queryParameters['scenario'] = requestParameters.scenario;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/housing-plan/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HousingPlanResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of housing plans per scenario
     */
    async apiHousingPlanList(requestParameters: ApiHousingPlanListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HousingPlanResponse>> {
        const response = await this.apiHousingPlanListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating a housing paln
     */
    async apiHousingPlanUpdateRaw(requestParameters: ApiHousingPlanUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HousingPlanObjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHousingPlanUpdate.');
        }

        if (requestParameters.housingPlanObjectRequest === null || requestParameters.housingPlanObjectRequest === undefined) {
            throw new runtime.RequiredError('housingPlanObjectRequest','Required parameter requestParameters.housingPlanObjectRequest was null or undefined when calling apiHousingPlanUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/housing-plan/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HousingPlanObjectRequestToJSON(requestParameters.housingPlanObjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HousingPlanObjectResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating a housing paln
     */
    async apiHousingPlanUpdate(requestParameters: ApiHousingPlanUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HousingPlanObjectResponse> {
        const response = await this.apiHousingPlanUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
