/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryEnum } from './CategoryEnum';
import {
    CategoryEnumFromJSON,
    CategoryEnumFromJSONTyped,
    CategoryEnumToJSON,
} from './CategoryEnum';
import type { ScenarioTypeEnum } from './ScenarioTypeEnum';
import {
    ScenarioTypeEnumFromJSON,
    ScenarioTypeEnumFromJSONTyped,
    ScenarioTypeEnumToJSON,
} from './ScenarioTypeEnum';

/**
 * 
 * @export
 * @interface ScenarioListResponse
 */
export interface ScenarioListResponse {
    /**
     * 
     * @type {number}
     * @memberof ScenarioListResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ScenarioListResponse
     */
    name: string;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof ScenarioListResponse
     */
    category?: CategoryEnum;
    /**
     * 
     * @type {ScenarioTypeEnum}
     * @memberof ScenarioListResponse
     */
    scenarioType?: ScenarioTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScenarioListResponse
     */
    project: number;
    /**
     * 
     * @type {Date}
     * @memberof ScenarioListResponse
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof ScenarioListResponse
     */
    handled?: number;
}

/**
 * Check if a given object implements the ScenarioListResponse interface.
 */
export function instanceOfScenarioListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "timestampLastModified" in value;

    return isInstance;
}

export function ScenarioListResponseFromJSON(json: any): ScenarioListResponse {
    return ScenarioListResponseFromJSONTyped(json, false);
}

export function ScenarioListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenarioListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'category': !exists(json, 'category') ? undefined : CategoryEnumFromJSON(json['category']),
        'scenarioType': !exists(json, 'scenario_type') ? undefined : ScenarioTypeEnumFromJSON(json['scenario_type']),
        'project': json['project'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'handled': !exists(json, 'handled') ? undefined : json['handled'],
    };
}

export function ScenarioListResponseToJSON(value?: ScenarioListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'category': CategoryEnumToJSON(value.category),
        'scenario_type': ScenarioTypeEnumToJSON(value.scenarioType),
        'project': value.project,
        'handled': value.handled,
    };
}

