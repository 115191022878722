import * as L from "leaflet";
import React from "react";
import { ContentTypeEnum } from "src/client/lib/models";
import { v4 as uuidv4 } from "uuid";
import "./ResultBlockMap.css";
interface Geometry {
  coordinates: [number, number][][] | [number, number][][][];
  type: "Polygon" | "MultiPolygon";
}

interface ParsedIsochrone {
  startingPoint: { latitude: number | null; longitude: number | null };
  features: {
    geometry: Geometry;
    properties: {
      minutes: number;
    };
  }[];
}

interface ParsedChanges {
  startingPoint: { latitude: number | null; longitude: number | null };
  features: {
    geometry: Geometry;
    properties: {};
  }[];
}

interface ResultBlockMapProps {
  geoJsonString: string;
  mapType: ContentTypeEnum;
}

export class ResultBlockMap extends React.Component<ResultBlockMapProps> {
  map?: L.Map;
  mapHolder: React.RefObject<HTMLDivElement>;
  mapId: string;
  isochroneColors: {
    minute: number;
    color: string;
  }[] = [
    {
      minute: 5,
      color: "#47B585",
    },
    {
      minute: 10,
      color: "#91cba8",
    },
    {
      minute: 15,
      color: "#ddf1b4",
    },
    {
      minute: 20,
      color: "#fedf99",
    },
    {
      minute: 25,
      color: "#f59053",
    },
    {
      minute: 30,
      color: "#d7191c",
    },
  ];

  // Groups
  isochroneGroup: L.FeatureGroup<any> = L.featureGroup();
  ChangesGroup: L.FeatureGroup<any> = L.featureGroup();

  constructor(props: any) {
    super(props);
    this.mapHolder = React.createRef();
    this.mapId = uuidv4();
  }

  render() {
    return <div ref={this.mapHolder} className="ResultBlockMap" />;
  }

  zoomToFeatureGroup(
    map: L.Map,
    featureGroup: L.FeatureGroup,
    fallbackLatitude?: number | null,
    fallbackLongitude?: number | null
  ) {
    if (featureGroup.getLayers().length > 0) {
      map.fitBounds(featureGroup.getBounds());
    } else {
      map.setView([fallbackLatitude || 52.09, fallbackLongitude || 5.11], 7);
    }
  }

  parseIsochroneJsonString(geojson: string): ParsedIsochrone {
    const returnData: ParsedIsochrone = {
      startingPoint: { latitude: null, longitude: null },
      features: [],
    };

    try {
      // parse json
      const isochroneObject = JSON.parse(geojson);

      // validate data then return
      if (
        "features" in isochroneObject &&
        "properties" in isochroneObject &&
        "type" in isochroneObject
      ) {
        if (
          isochroneObject.type === "FeatureCollection" &&
          "latitude" in isochroneObject.properties &&
          "longitude" in isochroneObject.properties
        ) {
          isochroneObject.features.forEach((feature: any) => {
            if (
              "geometry" in feature &&
              "properties" in feature &&
              "type" in feature
            ) {
              if (
                "coordinates" in feature.geometry &&
                "type" in feature.geometry &&
                "minutes" in feature.properties &&
                feature.type === "Feature"
              ) {
                if (
                  feature.geometry.type === "Polygon" ||
                  feature.geometry.type === "MultiPolygon"
                ) {
                  returnData.features.push({
                    geometry: {
                      coordinates: feature.geometry.coordinates,
                      type: feature.geometry.type,
                    },
                    properties: {
                      minutes: feature.properties.minutes,
                    },
                  });
                }
              }
            }
          });

          returnData.startingPoint.latitude =
            isochroneObject.properties.latitude;
          returnData.startingPoint.longitude =
            isochroneObject.properties.longitude;
        }
      }
      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        startingPoint: { latitude: null, longitude: null },
        features: [],
      };
    }
  }

  drawIsochrones() {
    if (this.map && this.props.mapType === ContentTypeEnum.Isochrone) {
      this.isochroneGroup.clearLayers();

      // From string to isochrone object
      const isochrone = this.parseIsochroneJsonString(this.props.geoJsonString);

      // Sort the isochrones so the smallest is always on top
      isochrone.features.sort(
        (a, b) => b.properties.minutes - a.properties.minutes
      );

      // Add the isochrones to the map
      isochrone.features.forEach((feature) => {
        const colorObject = this.isochroneColors.find(
          ({ minute }) => minute === feature.properties.minutes
        );
        const color = colorObject ? colorObject.color : "#2b83ba";

        L.geoJSON(feature.geometry, {
          style: {
            color: color,
            opacity: 1,
            weight: 1.5,
            lineCap: "round",
            lineJoin: "round",
            fillColor: color,
            fillOpacity: 0.15,
          },
        }).addTo(this.isochroneGroup);
      });

      // Zoom to layers if there are any
      this.zoomToFeatureGroup(
        this.map,
        this.isochroneGroup,
        isochrone.startingPoint.latitude,
        isochrone.startingPoint.longitude
      );
    }
  }

  parseChangesJsonString(geojson: string): ParsedChanges {
    const returnData: ParsedChanges = {
      startingPoint: { latitude: null, longitude: null },
      features: [],
    };

    try {
      // parse json
      const changesObject = JSON.parse(geojson);
      // validate data then return
      if (
        "features" in changesObject &&
        "properties" in changesObject &&
        "type" in changesObject
      ) {
        if (
          changesObject.type === "FeatureCollection" &&
          "latitude" in changesObject.properties &&
          "longitude" in changesObject.properties
        ) {
          changesObject.features.forEach((feature: any) => {
            if (
              "geometry" in feature &&
              "properties" in feature &&
              "type" in feature
            ) {
              if (
                "coordinates" in feature.geometry &&
                "type" in feature.geometry &&
                feature.type === "Feature"
              ) {
                if (feature.geometry.type === "LineString") {
                  returnData.features.push({
                    geometry: {
                      coordinates: feature.geometry.coordinates,
                      type: feature.geometry.type,
                    },
                    properties: {},
                  });
                }
              }
            }
          });

          returnData.startingPoint.latitude = changesObject.properties.latitude;
          returnData.startingPoint.longitude =
            changesObject.properties.longitude;
        }
      }
      return returnData;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        startingPoint: { latitude: null, longitude: null },
        features: [],
      };
    }
  }

  drawChanges() {
    if (this.map && this.props.mapType === ContentTypeEnum.ScenarioChanges) {
      this.ChangesGroup.clearLayers();

      // From string to isochrone object
      const changes = this.parseChangesJsonString(this.props.geoJsonString);
      // Add the isochrones to the map
      changes.features.forEach((feature) => {
        L.geoJSON(feature.geometry, {
          style: {
            color: "blue",
            opacity: 5,
            weight: 2,
            lineCap: "round",
            lineJoin: "round",
            fillColor: "blue",
            fillOpacity: 0.5,
          },
        }).addTo(this.ChangesGroup);
      });

      // Zoom to layers if there are any
      this.zoomToFeatureGroup(
        this.map,
        this.ChangesGroup,
        changes.startingPoint.latitude,
        changes.startingPoint.longitude
      );
    }
  }
  componentDidMount() {
    if (
      this.mapHolder.current != null &&
      this.mapHolder.current.childNodes.length === 0
    ) {
      // Initialize map
      const mapElement = document.createElement("div");
      mapElement.style.height = "100%";
      mapElement.style.width = "100%";
      mapElement.id = this.mapId;
      this.mapHolder.current.appendChild(mapElement);

      this.map = L.map(this.mapId, {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5,
        minZoom: 7,
        maxZoom: 15,
      });
      this.map.setView([52.09, 5.11], 7);
      // Add layers
      let layer = L.tileLayer(
        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        {
          opacity: 1,
        }
      );
      layer.addTo(this.map);

      this.isochroneGroup.addTo(this.map);

      this.ChangesGroup.addTo(this.map);
    }

    this.drawIsochrones();

    this.drawChanges();
  }

  componentDidUpdate() {
    this.drawIsochrones();

    this.drawChanges();
  }
}
