import React from "react";
import SectionTitle from "src/components/SectionTitle";
import { TextInput, TextInputType } from "src/components/TextInput";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./NewScenarioScreen.css";

import { FaPlus } from "react-icons/fa";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";

interface NewScenarioScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewScenarioScreen extends React.Component<NewScenarioScreenProps> {
  render() {
    return (
      <div id="NewScenarioScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="Nieuw scenario aanmaken" />
            </li>
          </ul>
        </div>
        <div id="NewScenarioScreen-ContentHolder">
          <SectionTitle subtitle={true}>Scenarionaam</SectionTitle>
          <div id="NewScenarioScreen-Info">
            Geef een unieke naam voor dit scenario
          </div>
          <TextInput
            type={TextInputType.TextSpacesNumbersLines}
            value={this.props.state.newScenario.name}
            onChange={(newValue) => {
              this.props.stateHandler.updateNewScenarioName(
                newValue,
                this.props.updateStateCallback
              );
            }}
            placeholder="Naam voor nieuw scenario"
          ></TextInput>
          <MainButton
            onClick={() => {
              this.props.stateHandler.saveNewScenario(
                true,
                this.props.updateStateCallback
              );
            }}
            icon={<FaPlus size={12} />}
            className="MainBtn OK"
          >
            Opslaan en bewerken
          </MainButton>
        </div>
      </div>
    );
  }
}
