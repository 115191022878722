import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class NewScenarioStateHandler {
  // new project screen
  initNewScenarioScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newScenario.name = "";
    this.state.screenState = ScreenState.NewScenario;
    callback(this.state);
  }

  updateNewScenarioName(
    this: AppStateHandler,
    newScenarioName: string | null,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newScenario.name = newScenarioName;
    callback(this.state);
  }

  saveNewScenario(
    this: AppStateHandler,
    editNetwork: boolean,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.selectedProject !== null) {
      if (
        this.state.newScenario.name === null ||
        this.state.newScenario.name === ""
      ) {
        this.displayAlert(
          "⚠️ Vul alstublieft een scenario-naam in.",
          5000,
          callback
        );
        return;
      } else {
        this.state.popUpState = PopUpState.waiting;
        this.scenarioApi
          .apiScenarioCreate({
            scenarioCreateRequest: {
              name: this.state.newScenario.name as string,
              project: this.state.selectedProject,
              scenarioType: this.state.newScenario.scenarioType,
            },
          })
          .then((createScenarioResponse) => {
            this.state.selectedScenario = createScenarioResponse.id;
            callback(this.state);
            this.loadProjects(() => {
              this.state.selectedScenario = createScenarioResponse.id;
              this.state.selectedProject = null;
              this.state.newScenario.name = null;
              callback(this.state);
              this.closePopUp(callback);
              if (editNetwork) {
                this.changeScreen(ScreenState.EditNetworkScreen, callback);
              } else {
                this.changeScreen(ScreenState.ScenarioResult, callback);
              }
            });
          })
          .catch((error) => {
            console.log("Error while creating scenario", error);
            //sign out
          });
        callback(this.state);
      }
    }
  }
}
