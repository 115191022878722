/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HousingPlanGeoJsonPolygon } from './HousingPlanGeoJsonPolygon';
import {
    HousingPlanGeoJsonPolygonFromJSON,
    HousingPlanGeoJsonPolygonFromJSONTyped,
    HousingPlanGeoJsonPolygonToJSON,
} from './HousingPlanGeoJsonPolygon';
import type { HousingTypeEnum } from './HousingTypeEnum';
import {
    HousingTypeEnumFromJSON,
    HousingTypeEnumFromJSONTyped,
    HousingTypeEnumToJSON,
} from './HousingTypeEnum';

/**
 * 
 * @export
 * @interface HousingPlanResponse
 */
export interface HousingPlanResponse {
    /**
     * 
     * @type {number}
     * @memberof HousingPlanResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof HousingPlanResponse
     */
    scenario: number;
    /**
     * 
     * @type {HousingPlanGeoJsonPolygon}
     * @memberof HousingPlanResponse
     */
    geom: HousingPlanGeoJsonPolygon;
    /**
     * 
     * @type {string}
     * @memberof HousingPlanResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HousingPlanResponse
     */
    numberOfHouses: number;
    /**
     * 
     * @type {HousingTypeEnum}
     * @memberof HousingPlanResponse
     */
    housingType: HousingTypeEnum;
}

/**
 * Check if a given object implements the HousingPlanResponse interface.
 */
export function instanceOfHousingPlanResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "scenario" in value;
    isInstance = isInstance && "geom" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "numberOfHouses" in value;
    isInstance = isInstance && "housingType" in value;

    return isInstance;
}

export function HousingPlanResponseFromJSON(json: any): HousingPlanResponse {
    return HousingPlanResponseFromJSONTyped(json, false);
}

export function HousingPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HousingPlanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'scenario': json['scenario'],
        'geom': HousingPlanGeoJsonPolygonFromJSON(json['geom']),
        'name': json['name'],
        'numberOfHouses': json['number_of_houses'],
        'housingType': HousingTypeEnumFromJSON(json['housing_type']),
    };
}

export function HousingPlanResponseToJSON(value?: HousingPlanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'scenario': value.scenario,
        'geom': HousingPlanGeoJsonPolygonToJSON(value.geom),
        'name': value.name,
        'number_of_houses': value.numberOfHouses,
        'housing_type': HousingTypeEnumToJSON(value.housingType),
    };
}

