// import { LineString, Point, Position } from "geojson";
import React from "react";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType } from "src/Types";
// import { EditTypeEnum } from "src/client/lib/models";
import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  Polygon,
  Polyline,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "./DrawAreaMap.css";

interface DrawAreaMapProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class DrawAreaMap extends React.Component<DrawAreaMapProps> {
  center: LatLng =
    this.props.state.current_user.center_area || new LatLng(52.3676, 4.9041); // Amsterdam, The Netherlands

  minZoom: number = 0;
  maxZoom: number = 18;
  initialZoom: number = 11;

  calcDistance(pointA: LatLng, pointB: LatLng): number {
    return Math.sqrt(
      Math.pow(pointA.lat - pointB.lat, 2) +
        Math.pow(pointA.lng - pointB.lng, 2)
    );
  }

  AreaPolygon = () => {
    useMapEvents({
      click: (event) => {
        const { lat, lng } = event.latlng;

        if (this.props.state.newProject.projectArea.coordinates.length > 0) {
          // Check if the clicked point is close to the first point
          if (
            this.calcDistance(
              this.props.state.newProject.projectArea.coordinates[0],
              new LatLng(lat, lng)
            ) <=
            0.0001 + 0.00005 * Math.pow(event.target._zoom - this.maxZoom, 2)
          ) {
            if (
              this.props.state.newProject.projectArea.coordinates.length === 1
            ) {
              // Reset the drawing if there are only two points
              this.props.state.newProject.projectArea.coordinates = [];
              this.props.state.newProject.projectArea.areaClosed = false;
              this.props.updateStateCallback(this.props.state);
            } else {
              // Close the shape
              this.props.state.newProject.projectArea.coordinates.push(
                this.props.state.newProject.projectArea.coordinates[0]
              );
              this.props.state.newProject.projectArea.areaClosed = true;
              this.props.updateStateCallback(this.props.state);
            }
          } else {
            // Add one point
            this.props.state.newProject.projectArea.coordinates.push(
              new LatLng(lat, lng)
            );
            this.props.updateStateCallback(this.props.state);
          }
        } else {
          // Add the first point
          this.props.state.newProject.projectArea.coordinates = [
            new LatLng(lat, lng),
          ];
          this.props.updateStateCallback(this.props.state);
        }
      },
      mousemove: (event) => {
        // Track the mouse to show where the next point will be
        const { lat, lng } = event.latlng;
        this.props.state.newProject.projectArea.mousePosition = new LatLng(
          lat,
          lng
        );
        this.props.updateStateCallback(this.props.state);
      },
    });

    const polygonOptions = { color: "#5d87ff" };

    if (
      this.props.state.newProject.projectArea.areaClosed &&
      this.props.state.newProject.projectArea.coordinates.length > 0
    ) {
      return (
        <Polygon
          pathOptions={polygonOptions}
          positions={this.props.state.newProject.projectArea.coordinates}
        />
      );
    } else if (
      this.props.state.newProject.projectArea.mousePosition &&
      this.props.state.newProject.projectArea.coordinates.length > 0
    ) {
      return (
        <Polyline
          pathOptions={polygonOptions}
          positions={[
            ...this.props.state.newProject.projectArea.coordinates,
            this.props.state.newProject.projectArea.mousePosition,
          ]}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <MapContainer
        center={this.center}
        zoom={this.initialZoom}
        maxZoom={this.maxZoom}
        minZoom={this.minZoom}
        id={"DrawAreaMap"}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <this.AreaPolygon />
      </MapContainer>
    );
  }
}
