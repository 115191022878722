import React from "react";
import { MdClose } from "react-icons/md";
import { AppStateHandler } from "src/AppStateHandler";
import MenuTitle from "src/components/MenuTitle";
import { AppStateType } from "src/Types";
import "./UserLogsPopUp.css";

interface UserLogsPopUpProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class UserLogsPopUp extends React.Component<UserLogsPopUpProps> {
  render() {
    return (
      <div id="UserLogsPopUp">
        <div id="UserLogsPopUp_Box">
          <div id="UserLogsPopUp_ContentWrapper">
            <MenuTitle
              title="De gebruikerslog"
              onClick={() => {
                this.props.state.userLogsPopUpVisable = false;
                this.props.state.userLogs = [];
                this.props.updateStateCallback(this.props.state);
              }}
              button={true}
              button_content={<MdClose size={25} />}
              transparentButtonBackground={true}
            />

            <div id="UserLogsPopUp_Content">
              <table className="Users-table">
                <thead>
                  <tr>
                    <th>log</th>
                    <th>datetime</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.state.userLogs.map((log) => (
                    <tr>
                      <td>{log.logMessage}</td>
                      <td>{log.timestamp.toString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
