import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class ProjectStateHandler {
  // project screen
  initProjectScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Project;
    this.getProject(callback);
    callback(this.state);
  }

  getProject(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const scenario = this.state.projects.find(
      (project) => project.id === this.state.selectedProject
    );
    if (scenario) {
      this.state.project.typescenario =
        scenario.scenarioList[0].scenarioType || "";
    }
    this.projectApi
      .apiProjectRetrieve({ id: this.state.selectedProject || 0 })
      .then((project) => {
        this.state.project.name = project.name;
        this.state.project.createdDate =
          project.timestampLastModified.toString();
        this.state.project.sourcedataDate = "2023";

        callback(this.state);
      });
  }

  deleteProject(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiProjectDestroy({ id: this.state.selectedProject as number })
      .then((response) => {
        this.loadProjects(() => {
          if (this.state.projects.length > 0) {
            this.changeScreen(ScreenState.ScenarioResult, () => {
              callback(this.state);
            });
          } else {
            this.changeScreen(ScreenState.NewProject, () => {
              callback(this.state);
            });
          }
        });
        callback(this.state);
      });

    callback(this.state);
  }
}
