import { LatLng } from "leaflet";
import { getSelectedScenario } from "src/helperFunctions";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { CategoryEnum, ScenarioTypeEnum } from "../../client/lib/models";

export class ScenarioResultStateHandler {
  // result screen
  initScenarioResultScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // this.state.scenarioScreen.loading = false;
    this.state.resultBlocks = [];
    this.state.screenState = ScreenState.ScenarioResult;
    const selectedScenario = getSelectedScenario(
      this.state.projects,
      this.state.selectedScenario,
      this
    );
    console.log("SELECTED SCENARIO", selectedScenario);
    if (selectedScenario !== null) {
      this.state.selectedScenario = selectedScenario.id;
      this.state.scenarioScreen.name = selectedScenario.name;
      this.state.scenarioScreen.category =
        selectedScenario.category as CategoryEnum;
      this.state.scenarioScreen.scenarioType =
        selectedScenario.scenarioType as ScenarioTypeEnum;
      this.state.newScenario.scenarioType =
        selectedScenario.scenarioType as ScenarioTypeEnum;

      switch (this.state.scenarioScreen.scenarioType) {
        case ScenarioTypeEnum.BicycleOrientedDevelopment:
          this.state.scenarioScreen.scenarioTypeInDutch =
            "Fietsgericht ontwikkelen";
          break;
        case ScenarioTypeEnum.AccessibilityInView:
          this.state.scenarioScreen.scenarioTypeInDutch =
            "Bereikbaarheid in beeld";
          break;
      }
      this.fetchResultBlocks(callback);
      callback(this.state);
      this.editNetworkClearMap(callback);
      return selectedScenario.name;
    } else {
      callback(this.state);
    }

    callback(this.state);
  }

  fetchResultBlocks(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    // Call the callback function with the current state
    callback(this.state);

    // Initialize the handled variable
    let handled: number = 0;

    /**
     * Checks if the scenario has been handled.
     * If not, it recursively calls itself after a timeout.
     */
    const checkHandled = () => {
      if (this.state.selectedScenario) {
        // Retrieve the scenario from the API
        this.scenarioApi
          .apiScenarioRetrieve({ id: this.state.selectedScenario })
          .then((response) => {
            // Update the handled variable with the response
            this.state.scenarioScreen.latLng = new LatLng(
              response.lat,
              response.lon
            );

            callback(this.state);
            handled = response.handled as number;

            // Retrieve the result blocks from the API
            if (this.state.selectedScenario) {
              this.resultBlockApi
                .apiResultblockList({
                  scenario: this.state.selectedScenario,
                })
                .then((response) => {
                  // Update the result blocks in the state if the scenario matches
                  if (response.length > 0) {
                    if (response[0].scenario === this.state.selectedScenario) {
                      this.state.resultBlocks = response;
                      callback(this.state);
                    }
                  } else {
                    this.state.resultBlocks = [];
                    callback(this.state);
                  }
                });
            }

            // If the scenario hasn't been handled, set loading to true and call checkHandled after a timeout
            if (handled < 2) {
              console.log("Scenario not handled yet", handled);
              this.state.scenarioScreen.loading = true;
              callback(this.state);
              setTimeout(() => {
                checkHandled();
              }, 5000);
            } else {
              // If the scenario has been handled, set loading to false
              console.log("Scenario has been handled", handled);
              this.state.scenarioScreen.loading = false;
              callback(this.state);
            }
          });
      }
    };

    // Call checkHandled to start fetching the result blocks
    checkHandled();
  }

  exportBlock(
    this: AppStateHandler,
    blockId: number,
    bloockName: string,
    callback: (newState: AppStateType) => void
  ) {
    const alert_id = this.addToAlerts(
      "🖼️ Bezig met het voorbereiden van uw export!",
      callback
    );
    callback(this.state);
    this.resultBlockApi
      .apiResultblockExportRetrieve({ id: blockId })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          blockId +
          "_" +
          this.state.scenarioScreen.name?.replace(/ /g, "_") +
          "_" +
          bloockName.replace(/ /g, "_") +
          ".png";
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        document.body.removeChild(link);
        this.removeFromAlerts(alert_id, callback);
      });
  }

  deleteScenario(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.scenarioApi
      .apiScenarioDestroy({ id: this.state.selectedScenario as number })
      .then((response) => {
        this.state.projects = [];
        this.loadProjects(() => {
          this.initScenarioResultScreen(callback);
        });
        callback(this.state);
      });
  }
}
