/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CenterAreaPoint } from './CenterAreaPoint';
import {
    CenterAreaPointFromJSON,
    CenterAreaPointFromJSONTyped,
    CenterAreaPointToJSON,
} from './CenterAreaPoint';

/**
 * 
 * @export
 * @interface UserMe
 */
export interface UserMe {
    /**
     * 
     * @type {number}
     * @memberof UserMe
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    isStaff?: boolean;
    /**
     * 
     * @type {CenterAreaPoint}
     * @memberof UserMe
     */
    centerArea: CenterAreaPoint;
}

/**
 * Check if a given object implements the UserMe interface.
 */
export function instanceOfUserMe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "centerArea" in value;

    return isInstance;
}

export function UserMeFromJSON(json: any): UserMe {
    return UserMeFromJSONTyped(json, false);
}

export function UserMeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'isStaff': !exists(json, 'is_staff') ? undefined : json['is_staff'],
        'centerArea': CenterAreaPointFromJSON(json['center_area']),
    };
}

export function UserMeToJSON(value?: UserMe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'is_staff': value.isStaff,
        'center_area': CenterAreaPointToJSON(value.centerArea),
    };
}

