import "./Alerts.css";
import React from "react";

interface AlertsProps {
  alerts: { id: number; text: string }[];
}

export default class Alerts extends React.Component<AlertsProps> {
  render() {
    return (
      <div id="Alerts">
        {this.props.alerts.map((i) => (
          <div key={i.id} className="Alert">
            {i.text}
          </div>
        ))}
      </div>
    );
  }
}
