import React from "react";
import { FaPlus } from "react-icons/fa";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import ViewCustomLayers from "../../components/ViewCustomLayers";
import { AppStateType, ScreenState } from "../../Types";
import "./CustomAnalysisLayersScreen.css";
interface CustomAnalysisLayersScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class CustomAnalysisLayersScreen extends React.Component<CustomAnalysisLayersScreenProps> {
  render() {
    return (
      <div id="CustomAnalysisLayersScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="Eigen bestemmingenlagen" />
            </li>
            <li>
              <MainButton
                onClick={() => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.CreateCustomAnalysisLayersScreen,
                    this.props.updateStateCallback
                  );
                }}
                icon={<FaPlus size={12} />}
                className="MainBtn"
              >
                Nieuwe bestemmingenlaag
              </MainButton>
            </li>
          </ul>
        </div>

        <div id="CustomAnalysisLayersScreen-ContentHolder">
          <div>
            {this.props.state.customAnalysisLayers.length === 0 &&
              "Je hebt nog geen bestemmingenlaag toegevoegd."}
            {this.props.state.customAnalysisLayers.map(
              ({ id, name, destinations, type }, index) => (
                <div key={index}>
                  <ViewCustomLayers
                    name={name}
                    type={type}
                    points={destinations.map((destination) => {
                      return destination.geom;
                    })}
                    deleteOnClick={
                      id
                        ? () => {
                            this.props.stateHandler.DeleteCustomAnalysisLayer(
                              id,
                              this.props.updateStateCallback
                            );
                          }
                        : undefined
                    }
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
