/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EditTypeEnum } from './EditTypeEnum';
import {
    EditTypeEnumFromJSON,
    EditTypeEnumFromJSONTyped,
    EditTypeEnumToJSON,
} from './EditTypeEnum';
import type { RoadLineStringGeom } from './RoadLineStringGeom';
import {
    RoadLineStringGeomFromJSON,
    RoadLineStringGeomFromJSONTyped,
    RoadLineStringGeomToJSON,
} from './RoadLineStringGeom';

/**
 * 
 * @export
 * @interface RoadListResponse
 */
export interface RoadListResponse {
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    gid?: number | null;
    /**
     * 
     * @type {EditTypeEnum}
     * @memberof RoadListResponse
     */
    editType: EditTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    speed: number;
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    reverseSpeed: number;
    /**
     * 
     * @type {RoadLineStringGeom}
     * @memberof RoadListResponse
     */
    geom: RoadLineStringGeom;
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    source: number;
    /**
     * 
     * @type {number}
     * @memberof RoadListResponse
     */
    target: number;
}

/**
 * Check if a given object implements the RoadListResponse interface.
 */
export function instanceOfRoadListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "editType" in value;
    isInstance = isInstance && "speed" in value;
    isInstance = isInstance && "reverseSpeed" in value;
    isInstance = isInstance && "geom" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "target" in value;

    return isInstance;
}

export function RoadListResponseFromJSON(json: any): RoadListResponse {
    return RoadListResponseFromJSONTyped(json, false);
}

export function RoadListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoadListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'gid': !exists(json, 'gid') ? undefined : json['gid'],
        'editType': EditTypeEnumFromJSON(json['edit_type']),
        'speed': json['speed'],
        'reverseSpeed': json['reverse_speed'],
        'geom': RoadLineStringGeomFromJSON(json['geom']),
        'source': json['source'],
        'target': json['target'],
    };
}

export function RoadListResponseToJSON(value?: RoadListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gid': value.gid,
        'edit_type': EditTypeEnumToJSON(value.editType),
        'speed': value.speed,
        'reverse_speed': value.reverseSpeed,
        'geom': RoadLineStringGeomToJSON(value.geom),
        'source': value.source,
        'target': value.target,
    };
}

