/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.09.10.16.05
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `TABLE` - Table
 * * `GRAPH` - Graph
 * * `GRAPH_DIFF` - Graph Diff
 * * `OVERVIEW` - Overview
 * * `ISOCHRONE` - Isochrone
 * * `HEATMAP` - Heatmap
 * * `HEATMAP_DIFF` - Heatmap Diff
 * * `SCENARIO_CHANGES` - Scenario Changes
 * * `MUNICIPALITY_GRAPH` - Municipality Graph
 * * `MUNICIPALITY_GRAPH_DIFF` - Municipality Graph Diff
 * * `HOUSING_PLAN_GRAPH` - Housing Plan Graph
 * * `PUPIL_DENSITY_NETWORK` - Pupil Density Network
 * @export
 */
export const ContentTypeEnum = {
    Table: 'TABLE',
    Graph: 'GRAPH',
    GraphDiff: 'GRAPH_DIFF',
    Overview: 'OVERVIEW',
    Isochrone: 'ISOCHRONE',
    Heatmap: 'HEATMAP',
    HeatmapDiff: 'HEATMAP_DIFF',
    ScenarioChanges: 'SCENARIO_CHANGES',
    MunicipalityGraph: 'MUNICIPALITY_GRAPH',
    MunicipalityGraphDiff: 'MUNICIPALITY_GRAPH_DIFF',
    HousingPlanGraph: 'HOUSING_PLAN_GRAPH',
    PupilDensityNetwork: 'PUPIL_DENSITY_NETWORK'
} as const;
export type ContentTypeEnum = typeof ContentTypeEnum[keyof typeof ContentTypeEnum];


export function ContentTypeEnumFromJSON(json: any): ContentTypeEnum {
    return ContentTypeEnumFromJSONTyped(json, false);
}

export function ContentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentTypeEnum {
    return json as ContentTypeEnum;
}

export function ContentTypeEnumToJSON(value?: ContentTypeEnum | null): any {
    return value as any;
}

