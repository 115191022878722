import React from "react";
import "./TextInput.css";

export enum TextInputType {
  UnsignedInteger,
  PositiveFloat,
  Percentage,
  SquareMeters,
  Hectares,
  TextSpacesNumbersLines,
  Password,
  Email,
  URL,
  LongText,
  RoadSpeed,
}

interface TextInputProps {
  type?: TextInputType;
  value?: string | null;
  placeholder?: string; //Only used when type==null or type==TextSpacesNumbersLines
  placeholderColor?: string;
  maxValue?: number;
  onChange: (newValue: string) => void;
  children?: React.ReactNode;
}

export class TextInput extends React.Component<TextInputProps> {
  inputIllegalSecondsReset = 2;
  timer: NodeJS.Timer | null = null;
  state = {
    value: "",
    afterText: "",
    focused: false,
    inputIllegalSeconds: 0,
  };
  inputType = "text";
  autoComplete = "off";

  onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.checkInput(event.target.value);
  };

  onFocus: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({
      focused: true,
    });
  };

  onBlur: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({
      focused: false,
    });
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.state.inputIllegalSeconds > 0) {
        this.setState({
          inputIllegalSeconds: this.state.inputIllegalSeconds - 1,
        });
      }
    }, 1000);

    if (this.props.value && this.props.value !== null) {
      this.checkInput(this.props.value, false);
    } else {
      this.checkInput("", false);
    }

    if (this.props.type === TextInputType.Password) {
      this.inputType = "password";
      this.autoComplete = "new-password";
    } else if (this.props.type === TextInputType.Email) {
      this.inputType = "email";
    }
  }

  componentDidUpdate() {
    if (this.props.value && this.props.value !== null) {
      if (this.state.value !== this.props.value) {
        this.checkInput(this.props.value, false);
      }
    }
  }

  checkInput = (inputValue: string, reportBack: boolean = true) => {
    /* DEFAULT */
    if (
      this.props.type === undefined ||
      this.props.type === TextInputType.Email ||
      this.props.type === TextInputType.Password
    ) {
      let afterText = "";
      if (inputValue.length === 0 && this.props.placeholder) {
        afterText = this.props.placeholder;
      }
      this.setState({
        value: inputValue,
        afterText: afterText,
      });
      if (reportBack) {
        this.props.onChange(inputValue);
      }
    } else if (this.props.type === TextInputType.UnsignedInteger) {
      /* UnsignedInteger */
      const regex = /^(?:0|(?:[0-9][0-9]*)?)$/;
      if (regex.test(inputValue)) {
        let afterText = "";
        if (inputValue.length === 0 && this.props.placeholder) {
          afterText = this.props.placeholder;
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.PositiveFloat) {
      /* PositiveFloat */
      const regex = /^(?:[0-9]+(?:\.[0-9]{0,5})?)?$/;
      if (regex.test(inputValue)) {
        let afterText = "0";
        if (inputValue.length > 0) {
          afterText = "";
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.Percentage) {
      /* Percentage */
      const regex = /^(?:0|(?:[1-9][0-9]?)?|100)$/;
      if (regex.test(inputValue)) {
        if (
          this.props.maxValue === undefined ||
          (this.props.maxValue !== undefined &&
            (parseInt(inputValue) || 0) <= this.props.maxValue)
        ) {
          let afterText = "0%";
          if (inputValue.length > 0) {
            afterText = "%";
          }
          this.setState({
            value: inputValue,
            afterText: afterText,
          });
          if (reportBack) {
            this.props.onChange(inputValue);
          }
        } else {
          this.setState({
            inputIllegalSeconds: this.inputIllegalSecondsReset,
          });
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.SquareMeters) {
      /* SquareMeters */
      const regex = /^(?:0|(?:[1-9][0-9]*)?)$/;
      if (regex.test(inputValue)) {
        let afterText = "0 m&sup2;";
        if (inputValue.length > 0) {
          afterText = " m&sup2;";
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.Hectares) {
      /* Hectares */
      const regex = /^(?:[0-9]+(?:\.[0-9]{0,5})?)?$/;
      if (regex.test(inputValue)) {
        let afterText = "0 Ha";
        if (inputValue.length > 0) {
          afterText = " Ha";
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.LongText) {
      /* Text */
      // const regex = /^(?:[a-zA-Z0-9/\-_ (),.]+)?$/;
      if (true) {
        let afterText = "";
        if (inputValue.length === 0 && this.props.placeholder) {
          afterText = this.props.placeholder;
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      }
    } else if (this.props.type === TextInputType.TextSpacesNumbersLines) {
      /* Text */
      const regex = /^(?:[a-zA-Z0-9/\-_ (),.]+)?$/;
      if (regex.test(inputValue)) {
        let afterText = "";
        if (inputValue.length === 0 && this.props.placeholder) {
          afterText = this.props.placeholder;
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.URL) {
      const regex = /^(?:[a-zA-Z0-9$-_.+!*'(),/&?=:%#]+)?$/;
      if (regex.test(inputValue)) {
        let afterText = "";
        if (inputValue.length === 0 && this.props.placeholder) {
          afterText = this.props.placeholder;
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    } else if (this.props.type === TextInputType.RoadSpeed) {
      /* RoadSpeed */
      const regex = /^(?:-|-1|(?:[0-9]+(?:\.[0-9]{0,5})?)?)$/;
      if (regex.test(inputValue)) {
        let afterText = "0 km/u";
        if (inputValue.length > 0) {
          afterText = " km/u";
        }
        this.setState({
          value: inputValue,
          afterText: afterText,
        });
        if (reportBack) {
          this.props.onChange(inputValue);
        }
      } else {
        this.setState({
          inputIllegalSeconds: this.inputIllegalSecondsReset,
        });
      }
    }
  };

  render() {
    return (
      <div
        className={
          this.state.focused
            ? "TextInput-Background TextInput-BackgroundFocused"
            : "TextInput-Background"
        }
      >
        <div className="TextInput-VisableText">
          <span className="TextInput-InvisableText">{this.state.value}</span>
          <span
            className="TextInput-Placeholder"
            dangerouslySetInnerHTML={{ __html: this.state.afterText }}
          ></span>
        </div>
        <input
          className="TextInput-Input"
          type={this.inputType}
          value={this.state.value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete={this.autoComplete}
        />
        {(() => {
          if (this.state.inputIllegalSeconds > 0) {
            return (
              <div className="TextInput-IllegalInputWarning">
                foutive invoer
              </div>
            );
          }
        })()}
      </div>
    );
  }
}
