import { AppStateHandler } from "../AppStateHandler";
import { AppStateType } from "../Types";

export class AlertsStateHandler {
  addToAlerts(
    this: AppStateHandler,
    text: string,
    callback: (newState: AppStateType) => void
  ): number {
    const newId = Math.max(...this.state.alerts.map((o) => o.id), 0) + 1;
    this.state.alerts.push({ id: newId, text: text });
    callback(this.state);
    return newId;
  }

  removeFromAlerts(
    this: AppStateHandler,
    id: number,
    callback: (newState: AppStateType) => void
  ) {
    const indexOfObject = this.state.alerts.findIndex((object) => {
      return object.id === id;
    });
    this.state.alerts.splice(indexOfObject, 1);
    callback(this.state);
  }

  displayAlert(
    this: AppStateHandler,
    text: string,
    time: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.alerts = [];
    const newId = this.addToAlerts(text, callback);
    setTimeout(() => {
      this.removeFromAlerts(newId, callback);
    }, time);
  }
}
