import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface MunicipalityGraphDiffProps {
  dataString: string;
}

export default class MunicipalityGraphDiff extends React.Component<MunicipalityGraphDiffProps> {
  static demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  render() {
    return (
      <ResponsiveContainer width="90%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={JSON.parse(this.props.dataString).data}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Inwoners"
            fill="#4187ee"
            activeBar={<Rectangle fill="#4187ee" stroke="#4187ee" />}
          />
          <Bar
            dataKey="Bereikbare inwoners oud"
            fill="#0041f5"
            activeBar={<Rectangle fill="#0041f5" stroke="#0041f5" />}
          />
          <Bar
            dataKey="Bereikbare inwoners nieuw"
            fill="#002999"
            activeBar={<Rectangle fill="#002999" stroke="#002999" />}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
